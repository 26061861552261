import http from './httpService';

/**************************************
  Payment services

  1. Get payment page data ->Checked
  2. Initiate razorpay payment ->Checked
  3. Add user details  ->Checked
  4. Get order details on success ->Checked
  5. Update order details ->Checked
   
***************************************/

// Get payment page details (Payment page)
export function getPaymentDetails(data) {
  return http.post('/api/course/ws/details', data);
}

// Initiate payment (Payment page)
export function initiatePayment(data) {
  return http.post(`/api/payment/initiate`, data);
}

// Add user details (Payment Page)
export function addUser(data) {
  return http.post(`/api/payment/add-user`, data);
}

// Get order details after payment success (Order success page)
export function getOrderDetail(id) {
  return http.get(`/api/order/${id}/success`);
}

// Update order (Order success page)
export function updateOrder(id) {
  return http.put(`/api/payment/order/${id}`);
}

// Get instalment page details (Instalment page)
export function getInstalmentDetails(id) {
  return http.get(`/api/payment/instalment/${id}`);
}

// Initiate instalments payment
export function initiateInstalmentPayment(data) {
  return http.post('/api/payment/instalment/initiate', data);
}

// Custom Payment API
export function createCustomOrder(data) {
  return http.post('/api/custom/payment/initiate', data);
}


// Instalment reminder API
export function getNextInstalmentInfo() {
  return http.get(`/api/user/pending/payment/notification`);
}